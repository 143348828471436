<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="selected.name"
                    v-bind:label="`${$t('Action')} ${$t('salesforce.name')}`"
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="selected.surveyQuestionId"
                    v-bind:label="`${this.$t('Question')} ${this.$t('Type')}`"
                    :items="surveyQuestionList"
                    item-text="label"
                    item-value="id"
                    :hide-details="true"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.brandId"
                    v-bind:label="
                      `${this.$t('brands.brand')} ${this.$t('brands.name')}`
                    "
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brand"
                    :loading="isSearching"
                    :items="filtered_brands"
                    hide-no-data
                    hide-selected
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.status"
                    v-bind:label="$t('status')"
                    :items="statusValues"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ `${this.$t("brands.Actions")}` }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="importExcelTemplatePathName"
              color="blue darken-1"
              @click="downloadExcelTemplateGlobal(importExcelTemplatePathName)"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2"
              :loading="isSelectingBrandactions"
              @click="onUploadExcelBrandactionsClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonTextBrandactions }}
            </v-btn>
            <input
              ref="uploaderBrandactions"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChangedBrandactions"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="brandactions-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "BrandActions",
  components: {},
  data: function() {
    return {
      statusValues: [],
      tableData: [],
      totaltableData: 0,
      importExcelTemplatePathName: null,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      filterable_col_list: ["name", "surveyQuestionId", "brand", "status"],
      filterCriteria: {
        name: {},
        surveyQuestionId: {},
        brand: {},
        status: {},
      },
      filterlist_fetch_url: "brands/actions/getfilterlist",
      showEmpty: true,

      /********** Import and Export Excel **********/
      isSelectingBrandactions: false,
      selectedFileBrandactions: null,
      defaultbuttonTextBrandactions: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** form-validation options **********/
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      showGoDialog: false,
      search_brand: null,
      isSearching: false,
      filtered_brands: [],
      surveyQuestionList: [],
    };
  },
  watch: {
    async search_brand(val) {
      await this.search_brand_list(val);
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    buttonTextBrandactions() {
      return this.isSelectingBrandactions
        ? "Uploading..."
        : this.defaultbuttonTextBrandactions;
    },
    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 70,
        sortBy: "ASC",
        fixed: "left",
      };
    },
    col_name() {
      return {
        key: "name",
        title: `${this.$t("Action")} ${this.$t("salesforce.name")}`,
        field: "name",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_surveyQuestionId() {
      return {
        key: "surveyQuestionId",
        title: `${this.$t("Question")} ${this.$t("Type")}`,
        field: "surveyQuestionId",
        align: "left",
        sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.surveyQuestionLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["surveyQuestionId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`surveyQuestionId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["surveyQuestionId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "surveyQuestionId")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brand") + " " + this.$t("brands.name"),
        field: "brand",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand && row.brand.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_status() {
      return {
        key: "status",
        title: `${this.$t("status")}`,
        field: "status",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.status}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["status"]}
                  dataList={this.statusValues}
                  filterCriteria={this.filterCriteria["status"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_edit() {
      return {
        key: "actions",
        title: this.$t("edititem"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        },
      };
    },
    columns() {
      return [
        this.col_id,
        this.col_name,
        this.col_surveyQuestionId,
        this.col_brand,
        this.col_status,
        this.col_edit,
      ];
    },
  },
  methods: {
    async search_brand_list(val) {
      if (!val) val = "";
      let fetch_url = `brands/actions/get_search_list?target=brand&&filter_name=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async get_selectable_question_list(val, brandId) {
      if (!val) val = "";
      let fetch_url = `brands/actions/get_search_list?target=selectableQuestions&&filter_name=${val}&&brandId=${brandId}`;
      const resp = await ApiService.get(fetch_url);
      this.surveyQuestionList = resp.data;
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#brandactions-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#brandactions-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "brands/actions";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.surveyQuestionList = resp.surveyQuestionList;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "brands/actions/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "actions.xlsx");
      this.close();
    },
    onUploadExcelBrandactionsClick() {
      this.isSelectingBrandactions = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingBrandactions = false;
        },
        { once: false }
      );
      this.$refs.uploaderBrandactions.click();
    },
    async onFileChangedBrandactions(e) {
      this.selectedFileBrandactions = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingBrandactions = true;
        let data = new FormData();
        data.append("file", this.selectedFileBrandactions);
        this.$refs.uploaderBrandactions.value = "";
        try {
          const response = await ApiService.post(
            `brands/actions/uploadexcelBrandactions/`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingBrandactions = false;
          await this.getDataFromApi();
        } catch (error) {
          console.log(error);
          this.isSelectingBrandactions = false;
        }
      }
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        actionType: _.get(item, "actionType"),
        surveyQuestionId: _.get(item, "surveyQuestionId"),
        survey_question: _.get(item, "survey_question"),
        brand: _.get(item, "brand"),
        brandId: _.get(item, "brandId"),
        status: _.get(item, "status"),
      };
      if (model.id) {
        if (model.brand) {
          this.filtered_brands = [model.brand];
        }
      } else {
        await this.search_brand_list("");
      }
      await this.get_selectable_question_list("", model.brandId);
      if (
        model.surveyQuestionId &&
        !this.surveyQuestionList
          .map((el) => el.id)
          .includes(model.surveyQuestionId)
      ) {
        let push_item = model.survey_question;
        push_item.label = `${push_item?.survey_component?.survey.name} / ${push_item?.survey_component.name} / ${push_item.name}`;
        this.surveyQuestionList.push(push_item);
      }
      if (model.actionType !== "QUESTION") {
        model.surveyQuestionId = model.actionType;
      }
      console.log(model);
      return model;
    },
    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["brand"]);
        let status = null;
        if (body.id) {
          status = await ApiService.put(`brands/actions/${body.id}`, body);
        } else {
          status = await ApiService.post(`brands/actions`, body);
        }
        if (status) {
          logInfo("Éxito !");
          this.getDataFromApi();
        } else {
          logError("Fallido !");
        }
        this.showEdit = false;
      }
    },
    init() {
      this.statusValues = [
        { value: "active", label: "active" },
        { value: "inactive", label: "inactive" },
      ];
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
